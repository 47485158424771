<script setup>

import Multiselect from "@vueform/multiselect";
import AppLayout from "@/Layout/AppLayout.vue";
import {computed, onMounted, reactive, ref, toRefs} from "vue";
import {Link, useForm, usePage} from "@inertiajs/vue3";
import {trans} from "@/Utils/translation.utils.js";

const seoData = usePage().props.seo_data
const formPaymentData = useForm({
    'invoice': null,
    'customerEmail': null,
    'customerData': {},
    'productId': null,
    'price':  0,
    'payment_system': null,
    'type':null,
    'checkout': {
        'country': null,
        'vat': 0,
        'sumVat': 0,
        'name_f': null,
        'name_l': null,
        'address': null,
        'city': null,
        'zip': null,
        'gree': true,
    }
})
const stateFormError = reactive({
    'country': false,
    'name_l': false,
    'name_f': false,
    'address': false,
    'city': false,
    'zip': false,
    'gree': false,
});

let  loading = false;

const props = defineProps({
    links: Object,
    optionSelect: Object,
    countries: Object,
    formServer: Object,
});


function changeCountry(country) {

    formPaymentData.checkout.vat = 0;
    formPaymentData.checkout.sumVat = 0;
    if(!country) return;

    let countrySelected = props.countries.find(c => c.id == country);

    let vat = countrySelected.vat
    formPaymentData.checkout.vat = parseFloat(vat);

    if(vat){
        let sumVat = formPaymentData.price * (vat/100);
        sumVat = sumVat.toFixed(2);
        formPaymentData.checkout.sumVat = sumVat;
    }
}

function cntErrValidateForm() {
    let errCnt = 0;
    for (var field in stateFormError) {
        if(formPaymentData.checkout[field]){
            stateFormError[field] = false;
        }
        else{
            errCnt++;
            stateFormError[field] = true;
        }
    }

    return errCnt;
}
function handleSubmit() {
    loading = true;

    if(cntErrValidateForm()){
        loading = false;
        return;
    }


    formPaymentData.post(route('paymentcheckout'), {
        preserveState: true,
        onSuccess: () => {
        },
        onerror : (errors) => {
            loading = false;
        },
        onFinish: () => {
            loading = false;
        },
    });
}
function goBack(){
    window.history.back();
}

onMounted(async () => {
    formPaymentData.price = props.formServer.price;
    formPaymentData.customerEmail = props.formServer.customerEmail;
    formPaymentData.customerData = props.formServer.customerData;
    formPaymentData.productId = props.formServer.productId;
    formPaymentData.payment_system = props.formServer.payment_system;
    formPaymentData.invoice = props.formServer.invoice;
    formPaymentData.type = props.formServer.type;
});
</script>

<template>
    <AppLayout>
        <section class="billing-address">
            <div class="container pb-5">
                <h1 class="page-title mb-4">{{seoData.h1_title}}</h1>
                <div class="bulk-orders-card c-card c-card--no-overflow p-4 p-lg-5">
                    <div class="mb-3 service-item__billing">
                        <div class="row">
                            <div class="col-12 col-lg-12 mb-3">
                                <strong class="text-dark">{{trans('front.country')}}</strong>
                                <div class="custom-select">
                                    <multiselect class="form-control" track-by="id" label="name"
                                                :class="{ 'is-invalid' : stateFormError.country }"
                                                :allow-empty="false"
                                                v-model="formPaymentData.checkout.country"
                                                :options="optionSelect"
                                                :placeholder="trans('front.country_select')"
                                                @change="changeCountry"
                                                :searchable="true"
                                                aria-label="pick a value"
                                    ></multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-3">
                                <strong class="text-dark">{{trans('front.name_f')}}</strong>
                                <input type="text" class="form-control form-control-lg"
                                       :class="{ 'is-invalid' : stateFormError.name_f }"
                                       :placeholder="trans('front.name_f_p')"
                                       v-model="formPaymentData.checkout.name_f"
                                >
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <strong class="text-dark">{{trans('front.name_l')}}</strong>
                                <input type="text" class="form-control form-control-lg"
                                       :placeholder="trans('front.name_l_p')" required=""
                                       :class="{ 'is-invalid' : stateFormError.name_l }"
                                       v-model="formPaymentData.checkout.name_l"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-12 mb-3">
                                <strong class="text-dark">{{trans('front.address')}}</strong>
                                <input type="text" class="form-control form-control-lg"
                                       :placeholder="trans('front.address_p')" required=""
                                       :class="{ 'is-invalid' : stateFormError.address }"
                                       v-model="formPaymentData.checkout.address"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 mb-3">
                                <strong class="text-dark">{{trans('front.zip')}}</strong>
                                <input type="text" class="form-control form-control-lg"
                                       :placeholder="trans('front.zip_p')" required=""
                                       :class="{ 'is-invalid' : stateFormError.zip }"
                                       v-model="formPaymentData.checkout.zip"
                                >
                            </div>
                            <div class="col-12 col-lg-6 mb-3">
                                <strong class="text-dark">{{trans('front.city')}}</strong>
                                <input type="text" class="form-control form-control-lg"
                                       :placeholder="trans('front.city')" required=""
                                       :class="{ 'is-invalid' : stateFormError.city }"
                                       v-model="formPaymentData.checkout.city"
                                >
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="checkbox-item">
                                    <input type="checkbox" id="checkbox"
                                           :class="{ 'is-invalid' : stateFormError.gree }"
                                           v-model="formPaymentData.checkout.gree"
                                    />
                                    <label for="checkbox">{{trans('front.gree1')}}
                                        <a target="_blank"  :href="links.term" >{{trans('front.gree2')}}</a>,
                                        <a target="_blank"  :href="links.police" >{{trans('front.gree3')}}</a>.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="service-item__billing mb-3 price-items">
                        <div class="price-item">
                            <strong class="text-dark">{{trans('front.price')}}</strong>
                            <div class="price">${{ formPaymentData.price.toFixed(2) }}</div>
                        </div>
                        <div class="price-item">
                            <strong class="text-dark vat">{{trans('front.vat')}} ({{ formPaymentData.checkout.vat }}%):</strong>
                            <div class="price_vat">${{ formPaymentData.checkout.sumVat }}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-12">
                            <div class="bulk-orders-card__footer w-100">
                                <div class="btn-footer">
                                    <div class="bulk-orders-card__price">${{ (parseFloat(formPaymentData.price) + parseFloat(formPaymentData.checkout.sumVat)).toFixed(2) }}</div>
                                    <button @click="handleSubmit"
                                            class="bulk-orders-card__order-btn btn btn-lg btn-success d-flex justify-content-center"
                                            :disabled="loading" style="max-width: 288px;">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status"
                                                aria-hidden="true"></span>
                                        <span v-if="!loading">{{trans('front.pay')}}</span>
                                        <span v-if="loading">Processing...</span>
                                    </button>
                                </div>
                                <div class="btn-back">
                                    <button class="btn" @click="goBack">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" class="svg-inline--fa fa-arrow-left sc-bczRLJ kQVxkf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" position="left"><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg>
                                        Back</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped></style>
