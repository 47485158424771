<script setup>
import AppLayout from "@/Layout/AppLayout.vue";
import {onMounted, ref} from 'vue';
import {useForm} from '@inertiajs/vue3'
import {trans} from "@/Utils/translation.utils.js";

const props = defineProps({
    invoiceId: {
        type: String,
        default: null
    },
    captureData: {
        type: Object,
        required: true
    },
    countries: {
        type: Object,
        required: true
    },
    currentBillingAddress: {
        type: Object,
        default: null
    }
});

const paymentFormInitialized = ref(false);
const currentStep = ref(props.currentBillingAddress ? 2 : 1);
const form = useForm({
    token: null,
    reference: null,
    last_four: null,
    user: null,
    billing_address: {
        first_name: '',
        last_name: '',
        address1: '',
        zip_code: '',
        city: '',
        state: '',
        country: '',
    }
});

onMounted(() => {
    if (props.currentBillingAddress) {
        form.billing_address = props.currentBillingAddress;

        nextStep();
    }
});

const handleSuccess = (result, submittedData) => {
    const token = result.token;
    const reference = result.reference;
    const user = result.user;
    const lastFour = submittedData.card_number.slice(-4);

    makeSubscription({
        token,
        reference,
        last_four: lastFour,
        user,
    });
};

const isLoading = ref(false);
const makeSubscription = async (payload) => {
    if (isLoading.value) {
        return;
    }

    isLoading.value = true;
    form.transform((data) => ({
        ...data,
        ...payload
    }))
        .post(route('subscription.makePayment', props.invoiceId), {
            onFinish: () => {
                isLoading.value = false;
            }
        });
};

const handleError = (error, submittedData) => {
    console.log(error);
};

const nextStep = () => {
    form.clearErrors();

    if (!form.billing_address.first_name || !form.billing_address.last_name ||
        !form.billing_address.address1 || !form.billing_address.zip_code ||
        !form.billing_address.city ||
        !form.billing_address.country) {
        form.setError('billingAddress', 'Please fill in all required fields');
        return;
    }

    const serverValidationUrl = route('subscription.validateBillingAddress')
    form.post(serverValidationUrl, {
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            initializePaymentForm();
            currentStep.value = 2;
        },
        onError: () => {
            currentStep.value = 1;
        }
    })
};

const prevStep = () => {
    currentStep.value = 1;
};

const initializePaymentForm = () => {
    if (paymentFormInitialized.value) {
        return;
    }

    const linkEl = document.createElement('link');
    linkEl.rel = 'stylesheet';
    linkEl.href = props.captureData.apiDomain+'/pcd/pcd_form.css';
    document.head.appendChild(linkEl);

    const scriptEl = document.createElement('script');
    scriptEl.src = props.captureData.apiDomain+'/pcd/pcd_form.js';
    scriptEl.onload = () => {
        window.pcd_form(document.getElementById("pcd_form"), {
            submit_secret: props.captureData.secret,
            submit_url: props.captureData.submitUrl,
            success_callback: handleSuccess,
            error_callback: handleError
        });

        const submitButton = document.getElementById("pcivault-pcd-form-button-submit");
        if (submitButton) {
            submitButton.textContent = trans('front.pay');
            submitButton.className = 'btn btn-success w-100';
        }

        //display none for pcivault-pcd-other-logo
        const otherLogo = document.getElementById("pcivault-pcd-other-logo");
        if (otherLogo) {
            otherLogo.style.display = 'none';
        }
    };
    document.head.appendChild(scriptEl);

    paymentFormInitialized.value = true;
};

const hasValidationErrorsInBillingAddress = (field) => {
    return form.errors[`billing_address.${field}`];
};
</script>

<template>
    <AppLayout>
        <section class="payment-section">
            <div class="container">
                <h1 class="page-title">
                    {{ trans('front.complete_payment')}}
                </h1>

                <div class="steps-indicator">
                    <div class="step" :class="{ active: currentStep === 1 }">
                        <span class="step-number">1</span>
                        <span class="step-title">
                            {{ trans('front.billing_address') }}
                        </span>
                    </div>
                    <div class="step" :class="{ active: currentStep === 2 }">
                        <span class="step-number">2</span>
                        <span class="step-title">
                            {{ trans('front.payment_details') }}
                        </span>
                    </div>
                </div>

                <div class="payment-content">
                    <div v-show="currentStep === 1" class="billing-card">
                        <div class="billing-form">
                            <div class="row g-3">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="first_name">
                                            {{ trans('front.first_name') }}
                                        </label>
                                        <input type="text" id="first_name" class="form-control" v-model="form.billing_address.first_name">
                                        <div v-if="hasValidationErrorsInBillingAddress('first_name')" class="form-error">{{form.errors['billing_address.first_name'] }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="last_name">
                                            {{ trans('front.last_name') }}
                                        </label>
                                        <input type="text" id="last_name" class="form-control" v-model="form.billing_address.last_name">
                                        <div v-if="hasValidationErrorsInBillingAddress('last_name')" class="form-error">{{form.errors['billing_address.last_name'] }}</div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="address1">
                                            {{ trans('front.address') }}
                                        </label>
                                        <input type="text" id="address1" class="form-control" v-model="form.billing_address.address1">
                                        <div v-if="hasValidationErrorsInBillingAddress('address1')" class="form-error">{{form.errors['billing_address.address1'] }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="zip_code">
                                            {{ trans('front.zip_code') }}
                                        </label>
                                        <input type="text" id="zip_code" class="form-control" v-model="form.billing_address.zip_code">
                                        <div v-if="hasValidationErrorsInBillingAddress('zip_code')" class="form-error">{{form.errors['billing_address.zip_code'] }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="city">
                                            {{ trans('front.city') }}
                                        </label>
                                        <input type="text" id="city" class="form-control" v-model="form.billing_address.city">
                                        <div v-if="hasValidationErrorsInBillingAddress('city')" class="form-error">{{form.errors['billing_address.city'] }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="state">
                                            {{ trans('front.state') }}
                                        </label>
                                        <input type="text" id="state" class="form-control" v-model="form.billing_address.state">
                                        <div v-if="hasValidationErrorsInBillingAddress('state')" class="form-error">{{form.errors['billing_address.state'] }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="country">
                                            {{ trans('front.country') }}
                                        </label>
                                        <select id="country" class="form-control" v-model="form.billing_address.country">
                                            <option value="">
                                                {{ trans('front.select_country') }}
                                            </option>
                                            <option v-for="country in props.countries.data" :key="country.code" :value="country.code">
                                                {{ country.name }}
                                            </option>
                                        </select>
                                        <div v-if="hasValidationErrorsInBillingAddress('country')" class="form-error">{{form.errors['billing_address.country'] }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.errors.billing_address" class="form-error mt-3">{{form.errors.billing_address }}</div>
                            <div class="form-actions">
                                <button @click="nextStep" class="btn btn-primary">
                                    {{ trans('front.continue_to_payment') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-show="currentStep === 2" class="payment-card">
                        <div id="pcd_form"></div>
                    </div>
                </div>
            </div>
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
.payment-section {
    padding: 2rem 0 4rem;
    background-color: #f8f9fa;
}

.steps-indicator {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    gap: 4rem;

    .step {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        color: #718096;

        &.active {
            color: #2c3e50;

            .step-number {
                background-color: #2d4472;
                color: white;
            }
        }

        .step-number {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #e2e8f0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 0.875rem;
        }

        .step-title {
            font-weight: 500;
        }
    }
}

.page-title {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #2c3e50;
    text-align: center;
}

.billing-card,
.payment-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1rem;

    label {
        display: block;
        margin-bottom: 0.5rem;
        font-weight: 500;
        color: #4a5568;
    }

    .form-control {
        width: 100%;
        padding: 0.625rem;
        border: 1px solid #e2e8f0;
        border-radius: 6px;
        transition: border-color 0.2s;

        &:focus {
            border-color: #2d4472;
            outline: none;
        }
    }
}

.form-error {
    color: #e53e3e;
    font-size: 0.875rem;
    margin-top: 0.375rem;
}

.form-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
}

.btn {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;

    &.btn-primary {
        background-color: #2d4472;
        color: white;
        border: none;

        &:hover {
            background-color: #2779bd;
        }
    }

    &.btn-outline-secondary {
        background-color: transparent;
        border: 1px solid #718096;
        color: #718096;

        &:hover {
            background-color: #f7fafc;
        }
    }
}

@media (max-width: 767px) {
    .payment-section {
        padding: 1.5rem 0 3rem;
    }

    .billing-card,
    .payment-card {
        padding: 1.5rem;
    }

    .steps-indicator {
        gap: 2rem;
    }
}
</style>
