<script setup>
    import { ref, computed, defineEmits } from 'vue'
    import AppLayout from '@/Layout/AppLayout.vue'
    import HowWork from '@/Components/General/HowWork.vue'
    import HowBuy from '@/Components/General/HowBuy.vue'
    import {trans} from "@/Utils/translation.utils.js";
    import { usePage, useForm, Link } from '@inertiajs/vue3'
    import ReCaptcha from '@/Components/Partials/ReCaptcha.vue';
    import AlertComponent from '@/Components/Partials/Alert.vue'

    const seoData = usePage().props.seo_data
    const form = useForm({
        name: '',
        message: '',
        email: '',
        recaptchaToken: ''
    });

    const isSuccess = ref(false)
    const validationForm = ref()
    const formErrors = ref({});

    const handleToken = (token) => {
        formErrors.value.recaptchaToken = '';
        form.recaptchaToken = token;
    };
    const handleExpired = () => {
        formErrors.value.recaptchaToken = trans('front_payment.validation.recaptcha_expired');
    };
    const handleSubmit = () => {
        validationForm.value.classList.add('was-validated');
        form.post(route('contacts.send'), {
            onSuccess: () => {
                form.reset();
                validationForm.value.classList.remove('was-validated');
                isSuccess.value = true
            },
            onError: (errors) => {
                formErrors.value = errors;
            }
        });
    };

</script>

<template>
    <AppLayout>
        <section>
            <div class="container pb-5">
                <h1 class="page-title mb-4 lh-sm">{{seoData.h1_title}}</h1>
                <div class="pb-4 text-center lh-sm">
                    <span v-html="seoData.top_text"></span>
                </div>
                <div class="contacts-card rounded-4 shadow overflow-hidden">
                    <div class="row g-0">
                        <div class="col-12 col-lg-7">
                            <div class="py-4 px-4 px-md-5">
                                <div class="row g-0 justify-content-center align-items-stretch">
                                    <div v-if="!isSuccess" class="col-12 col-lg-8">
                                        <div class="h-100">
                                            <div class="pt-2 pb-4">
                                                <h3 class="fs-5 lh-sm mb-0 text-center">{{ trans('front_contacts_form.title_h3') }}</h3>
                                            </div>
                                            <form @submit.prevent="handleSubmit" ref="validationForm" class="needs-validation pb-4" novalidate>
                                                <div class="input-group has-validation mb-3">
                                                    <input
                                                        v-model="form.name"
                                                        type="text"
                                                        class="form-control form-control-lg"
                                                        :placeholder="trans('front_contacts_form.name_placeholder')"
                                                        required
                                                    />
                                                    <div v-if="formErrors.name" class="invalid-feedback">
                                                        {{ formErrors.name }}
                                                    </div>
                                                </div>
                                                <div class="input-group has-validation mb-3">
                                                    <input
                                                        type="email"
                                                        v-model="form.email"
                                                        class="form-control form-control-lg"
                                                        :placeholder="trans('front_contacts_form.email_placeholder')"
                                                        required
                                                    />
                                                    <div v-if="formErrors.email" class="invalid-feedback">
                                                        {{ formErrors.email }}
                                                    </div>
                                                </div>
                                                <div class="input-group has-validation mb-3">
                                                    <textarea
                                                        v-model="form.message"
                                                        class="form-control form-control-lg"
                                                        :placeholder="trans('front_contacts_form.message_placeholder')"
                                                        rows="6"
                                                        required
                                                    />
                                                    <div v-if="formErrors.message" class="invalid-feedback">
                                                        {{ formErrors.message }}
                                                    </div>
                                                </div>
                                                <div class="row g-0 justify-content-center has-validation mb-3">
                                                    <div class="col-12 col-md-6 col-lg-8">
                                                        <ReCaptcha @tokenVerified="handleToken" @tokenExpired="handleExpired" :error="formErrors.recaptchaToken"/>
                                                    </div>
                                                </div>
                                                <div class="row g-0 justify-content-center">
                                                    <div class="col-12 col-md-6">
                                                        <button class="w-100 btn btn-lg btn-success" type="submit">
                                                            {{ trans('front_contacts_form.button_text') }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div v-else class="col-12">
                                        <AlertComponent type="success" :message="trans('front_contacts_form.message_success')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-5">
                            <div class="contacts-card__info h-100 bg-primary text-white py-4 px-4 py-md-5 px-md-5 py-lg-4">
                                <div class="row g-0 justify-content-center align-items-center h-100">
                                    <div class="col-12 col-lg-9">
                                        <div class="d-flex align-items-center mb-3">
                                            <img class="me-3" src="/img/icons/icon-whatsapp-white.svg" alt="Whatsapp" width="32" height="32" />
                                            <a class="fs-5 lh-sm fw-semibold text-white" href="https://wa.me/18025001015" target="_blank">+1 (802) 500-1015</a>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <img class="me-3" src="/img/icons/icon-telegram-white.svg" alt="Telegram" width="32" height="32" />
                                            <a class="fs-5 lh-sm fw-semibold text-white" href="https://t.me/viplikes_support" target="_blank">@viplikes_support</a>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <img class="me-3" src="/img/icons/icon-mail-white.svg" alt="Mail" width="32" height="32" />
                                            <a class="fs-5 lh-sm fw-semibold text-white" href="mailto:support@viplikes.net">support@viplikes.net</a>
                                        </div>
                                        <div class="d-flex">
                                            <img class="me-3" src="/img/icons/icon-location-white.svg" alt="Location" width="32" height="32" loading="lazy"/>
                                            <span class="fs-5 lh-sm fw-semibold text-white">
                                                Vipsocmedia SRL<br> CIF: 49212995<br> București, Sector 3 Mihai Bravu Nr 255 Subsol Modul S146<br> Romania
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <HowWork />
            <HowBuy />
        </section>
    </AppLayout>
</template>

<style lang="scss" scoped>
    .contacts-card {
        border: 1px solid #C8D4E0;

        &__info {
            font-family: 'Ruda', 'Montserrat', sans-serif;
        }
    }
</style>
