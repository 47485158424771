<script setup>
  import { ref, onMounted, watch } from 'vue'
  import { usePage } from '@inertiajs/vue3'
  import {trans} from "@/Utils/translation.utils.js";
  import Multiselect from '@vueform/multiselect'
  import Trustpilot from "@/Components/Partials/Trustpilot.vue";
  import LazyLoadComponent from "@/Components/LazyLoadComponent.vue";

  const langList = usePage().props.selectorLanguageData
  const linksList = usePage().props.nav_bar_data.footer
  const selectedLang = ref(null);

  const currentUrl = ref('');

  watch(selectedLang, (newLang, oldLang) => {
      if (typeof window !== 'undefined') {
          if (newLang !== oldLang && typeof oldLang !== 'undefined' && newLang !== currentUrl.value) {
              window.location.href = newLang;
          }
      }
  });

  const paymentIconList = [
    {
      icon: '/img/icons/bitcoin-white.svg',
      alt: 'Bitcoin'
    },
    {
      icon: '/img/icons/visa-white.svg',
      alt: 'Visa'
    },
    {
      icon: '/img/icons/mastercard-white.svg',
      alt: 'Mastercard'
    },
    {
      icon: '/img/icons/amex-white.svg',
      alt: 'Amex'
    },
    {
      icon: '/img/icons/applepay-white.svg',
      alt: 'Apple Pay'
    }
  ]

  const showAsUrl = (url) => {
      return currentUrl.value !== url;
  };

  onMounted(() => {
      if (typeof window !== 'undefined') {
          const url = new URL(window.location.href);
          currentUrl.value = url.href.replace(/\/$/, '');

      }
      selectedLang.value = currentUrl.value
  });

  const currentYear = new Date().getFullYear();

</script>

<template>
  <footer class="main-footer">
    <div class="container">
      <div class="row g-4 mb-4">
        <div class="col-12 col-md-6 col-xl-3">
          <h3>{{ trans('front.footer_h3_our_products') }}</h3>
          <ul v-for="item in linksList.outProducts" class="main-footer__main-list">
            <li>
              <a v-if="showAsUrl(item.link)" :href="item.link || '#'">{{item.title}}</a>
                <span v-else>{{item.title}}</span>
            </li>
          </ul>
          <LazyLoadComponent>
              <Multiselect v-model="selectedLang" :options="langList" :can-clear="false" open-direction="top">
                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    <img class="character-label-icon me-2" :src="value.icon" :alt="value.name" width="24" height="24" loading="lazy"/>
                    {{ value.name }}
                  </div>
                </template>

                <template v-slot:option="{ option }">
                  <img class="character-option-icon me-2" :src="option.icon" :alt="option.name" width="24" height="24" loading="lazy"/>
                  {{ option.name }}
                </template>
              </Multiselect>
          </LazyLoadComponent>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <h3>{{ trans('front.footer_h3_support_information') }}</h3>
          <p>{{ trans('front.footer_text_support_information') }}</p>
            <ul v-for="item in linksList.info">
                <li class="mb-3">
                    <a v-if="showAsUrl(item.link)"
                       :href="item.link || '#'">
                        {{item.title}}
                    </a>
                    <span v-else>{{item.title}}</span>
                </li>
            </ul>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <h3>{{ trans('front.footer_h3_contacts') }}</h3>
          <p>{{ trans('front.footer_text_contacts') }}
              <a v-if="showAsUrl(linksList.contacts)" class="text-decoration-underline" :href="linksList.contacts || '#'">
                  {{trans('front.footer_text_contacts_link') }}
              </a>
              <span v-else class="text-decoration-underline">{{trans('front.footer_text_contacts_link') }}</span>
          </p>
          <div class="d-flex mt-5">
            <img class="me-3" src="/img/icons/location.svg" alt="Location" width="40" height="40" loading="lazy" />
            <p>
              Vipsocmedia SRL <br />
              București, Sector 3 Mihai Bravu Nr 255 Subsol Modul S146
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <div class="main-footer__moneyback">
            <img src="/img/icons/moneyback-footer.svg" alt="Moneyback" width="112" height="112" loading="lazy"/>
            <span>{{ trans('front.footer_text_money_back') }}</span>
          </div>
          <div class="main-footer__payment d-flex align-items-center">
            <img
              v-for="item in paymentIconList"
              :key="item.alt"
              class="me-2"
              :src="item.icon"
              :alt="item.alt"
              width="50"
              height="31"
              loading="lazy"
            />
          </div>
            <Trustpilot/>
        </div>
      </div>
      <p class="text-center">
          {{ trans('front.footer_text_intro') }}
      </p>
      <div class="main-footer__copyrights">Viplikes © Copyright. 2013-{{currentYear}} {{ trans('front.footer_text_all_rights_reserved') }}</div>
    </div>

  </footer>
</template>

<style lang="scss">
  .main-footer {
    background-color: #2d4472;
    padding: 3rem 0 2rem;
    color: #fff;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 1.25rem;
    }

    a {
      color: #fff;
    }

    &__main-list {
      font-size: 1.25rem;
      margin-bottom: 1.25rem;

      li {
        margin-bottom: 0.75rem;
      }
    }

    &__moneyback {
      position: relative;
      margin-bottom: 2rem;

      img {
        position: relative;
        z-index: 5;
      }

      span {
        position: absolute;
        z-index: 4;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 0.8125rem 1rem;
        padding-left: 8.125rem;
        background-color: #253b65;
        border-radius: 2.0625rem;
        font-weight: 700;
      }
    }

    &__payment {
      margin-bottom: 2rem;
    }

    &__copyrights {
      font-size: 1.25rem;
      text-align: center;
    }

    .multiselect {
      background-color: #253b65;
      border: none;
      border-radius: 0.5rem;

      &.is-open {
        border-radius: 0.5rem;
      }

      &.is-active {
        box-shadow: none;
      }
    }

    .multiselect-caret {
      display: none;
    }

    .multiselect-dropdown {
      border: 0.0625rem solid #c8d4e0;
      border-radius: 0.5rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.075);
      overflow: hidden;
    }

    .multiselect-options {
      padding: 0.5rem;
      overflow: auto;
    }

    .multiselect-option {
      color: #2d4472;
      padding: 0.5rem;

      &.is-selected {
        background: #f5f5f5;
        color: #2d4472;
      }
    }
  }
</style>
